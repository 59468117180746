import Vue from 'vue' // Импортируем Vue
import store from '@/store/index' // Импортируем Store
import axios from '@/plugins/axios'
import PortalVue from 'portal-vue'
import bus from '@/plugins/bus'
import VueCookie from 'vue-cookie'
import { gsap, ScrollTrigger, ScrollToPlugin } from 'gsap/all'
import Notifications from 'vue-notification'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

require('@/assets/css/viewer.css')
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

Vue.use(VueCookie)
Vue.use(PortalVue)
Vue.use(Notifications)

Vue.config.productionTip = false // Отключаем предупреждение о режиме разработки
Vue.config.devtools = false // Отключаем дебагер false-выключено

/*
Vue.config.errorHandler = function(err, vm, info) {
    console.log(`Error: ${err.toString()}\nInfo: ${info}`);
}
Vue.config.warnHandler = function(msg, vm, trace) {
    console.log(`Warn: ${msg}\nTrace: ${trace}`);
}
*/

// Подключаем глобально стили
import '@/assets/tailwind.css'
import '@/assets/css/animate.css'
//import '@/assets/css/style.scss'
//import '@/assets/output.css'
// require('@/assets/css/style.scss')
// require('@/assets/css/animate.css')
// Подключаем Axios
Vue.prototype.$axios = axios

// Создаем event bus
Vue.prototype.$bus = bus

// Вшиваем в this Store
Vue.prototype.$store = store

export default Vue
